import { isEnableServer } from '@virginexperiencedays/feature-flags';
import { v4 as uuidv4 } from 'uuid';

import { FBEvent } from './types';

/**
 * Trigger custom Facebook Event
 */
export const fbEvent = async (event: FBEvent): Promise<void> => {
  if (!isEnableServer('FF_fb_capi')) return;

  const apiKey = process?.env?.NEXT_PUBLIC_API_FB_EVENT_SECRET;

  if (!apiKey) {
    console.error('[FB Event] Missing NEXT_PUBLIC_API_FB_EVENT_SECRET env');
    return;
  }

  const {
    eventName,
    contentName,
    contentCategory,
    email,
    phone,
    firstName,
    lastName,
    country,
    city,
    zipCode,
    products,
    currency,
  } = event;
  const eventId = event?.eventId || uuidv4();

  const body = JSON.stringify({
    eventName,
    eventId,
    contentName,
    contentCategory,
    email,
    phone,
    firstName,
    lastName,
    country,
    city,
    zipCode,
    products,
    currency,
    userAgent: navigator.userAgent,
    sourceUrl: window.location.href,
  });

  await fetch('/api/fb-events', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    },
    body,
  });
};
