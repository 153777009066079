export function slugToName(slug: string): string {
  if (!slug) return '';

  return slug
    .split(/\s|-/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function nameToSlug(name: string): string {
  if (!name) return '';

  return name
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('-');
}
